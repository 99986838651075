"use client";

// export default function Error({
//   error,
// }: {
//   error: Error & { digest?: string };
// }) {

export default function Error() {
  return (
    <div className="flex flex-col items-center justify-center h-screen gap-4">
      <div className="text-2xl font-bol text-foreground">
        Something went wrong
      </div>
      <div className="text-sm text-muted-foreground">Contact support</div>
    </div>
  );
}
